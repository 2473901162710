import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./pages/main";
import { Theme } from "@radix-ui/themes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Theme>
    <Main />
  </Theme>
);
